import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`We love all messaging platforms.`}</h2>
    <p>{`We’re excited to launch an `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/sdk/twitter"
      }}>{`analytics solution`}</a>{` for Twitter DM Bots to provide developers and marketers with actionable insights on their conversational data.`}</p>
    <h3>{`Analytics for Bot Specific Insights`}</h3>
    <p>{`Conversational analytics are unlike any other kind of data analytics. Traditional analytics are not suited for chat and voice assistants and there are a few reasons why. Tracking mechanisms are different and click stream tracking or event-based tracking loses the richness of messaging. Bots receive an array of unstructured data — text, audio, images, videos, and more. Therefore, the way the data is processed and the types of reports generated are significantly different than web or app analytics.`}</p>
    <p>{`In addition to providing table stakes metrics like engagement and retention, Dashbot enables you to view detailed analytics, see how your bot stacks up in market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment, and add human-in-the-loop to your bot.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“We’re excited to partner with Dashbot to give our brand and developer partners powerful analytic tools for experiences they are building on `}<a parentName="p" {...{
          "href": "https://blog.twitter.com/developer/en_us/topics/tools/2017/new-apis-to-power-the-future-of-customer-engagement-in-direct-me.html"
        }}>{`Twitter’s Direct Messaging platform`}</a>{`. It further allows our partners to learn, optimize, and take action from deep insights that Dashbot provides.”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`– Winson Wong, Partnerships Lead of Twitter Business Messaging`}</p>
    </blockquote>
    <h3>{`Integration is Simple`}</h3>
    <p>{`Using our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/sdk/twitter"
      }}>{`REST API`}</a>{`, you can integrate Dashbot seamlessly and start getting your Twitter bot’s analytics immediately.`}</p>
    <p>{`Overall, there are a few pieces of data that we need you to pull out of the Twitter JSON, and then the rest of the Twitter JSON goes in `}<inlineCode parentName="p">{`platformJson.`}</inlineCode></p>
    <h3>{`1. When your bot sends a message`}</h3>
    <p>{`When your bot sends a message, POST to the following endpoint:`}</p>
    <p><em parentName="p">{`Make sure to set the ‘Content-Type’ header to ‘application/json’`}</em></p>
    <pre><code parentName="pre" {...{}}>{`https://tracker.dashbot.io/track?platform=twitter&v=0.8.2-rest&type=outgoing&apiKey=<API_KEY>
`}</code></pre>
    <p>{`The data to POST should be in the following format.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "text": "What's your favorite type of bird?",
  "userId": "1234858592",
  "platformJson": {
    "direct_message_events": [
      {
        "type": "message_create",
        "id": "1234858585",
        "created_timestamp": "1392078023507",
        "message_create": {
          "target": {
            "recipient_id": "3805104374"
          },
          "sender_id": "1234858592",
          "source_app_id": "8829219",
          "message_data": {
            "text": "What's your favorite type of bird?",
            "entities": {
              "hashtags": [],
              "symbols": [],
              "urls": [],
              "user_mentions": []
            },
            "quick_reply": {
              "type": "options",
              "options": [
                {
                  "label": "Red Bird",
                  "metadata": "external_id_1"
                },
                {
                  "label": "Blue Bird",
                  "metadata": "external_id_2"
                },
                {
                  "label": "Black Bird",
                  "metadata": "external_id_3"
                },
                {
                  "label": "White Bird",
                  "metadata": "external_id_4"
                }
              ]
            },
            "attachment": {
              "type": "media",
              "media": {}
            }
          }
        }
      }
    ],
    "users": {
      "1234858592": {
        "id": "1234858592",
        "created_timestamp": "1415320482361",
        "name": "TwitterDev",
        "screen_name": "TwitterDev",
        "location": "Internet",
        "description": "Developer and Platform Relations @Twitter. We are developer advocates. We can't answer all your questions, but we listen to all of them!",
        "protected": false,
        "verified": true,
        "followers_count": 440643,
        "friends_count": 1534,
        "statuses_count": 2837,
        "profile_image_url": "http://pbs.twimg.com/profile_images/530814764687949824/npQQVkq8_normal.png",
        "profile_image_url_https": "https://pbs.twimg.com/profile_images/530814764687949824/npQQVkq8_normal.png"
      },
      "3805104374": {
        "id": "3805104374",
        "created_timestamp": "1449607341142",
        "name": "Furni",
        "screen_name": "furni",
        "location": "San Francisco, CA",
        "description": "Furni is Twitter's example company to showcase new developer features.",
        "protected": false,
        "verified": false,
        "followers_count": 297,
        "friends_count": 7,
        "statuses_count": 1,
        "profile_image_url": "http://pbs.twimg.com/profile_images/653712801219805185/S4LvoO9b_normal.png",
        "profile_image_url_https": "https://pbs.twimg.com/profile_images/653712801219805185/S4LvoO9b_normal.png"
      }
    },
    "apps": {
      "8829219": {
        "id": "8829219",
        "name": "Furni",
        "url": "https://twitter.com/furni"
      }
    }
  }
}
`}</code></pre>
    <h4>{`Sample cURL`}</h4>
    <pre><code parentName="pre" {...{}}>{`curl -X POST -H "Content-Type: application/json"
      -d '{"text":"What is your favorite type of bird?","userId":"1234858592","platformJson":{"direct_message_events":[]}}'
      '<a href="https://tracker.dashbot.io/track?platform=twitter&v=0.8.2-rest&type=outgoing&apiKey=API_KEY%27" rel="noopener" target="_blank">https://tracker.dashbot.io/track?platform=twitter&v=0.8.2-rest&type=outgoing&apiKey=API_KEY'</a>
`}</code></pre>
    <h4>{`This is a sample outgoing message.`}</h4>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/07/07120635/398f6-10yuvlnnq5aagw3zqsckf9q.png)
    </figure>
### 2. When your bot receives a message
    <p>{`When your bot receives a message, POST to the following endpoint:`}</p>
    <p><em parentName="p">{`Make sure to set the ‘Content-Type’ header to ‘application/json’`}</em></p>
    <pre><code parentName="pre" {...{}}>{`https://tracker.dashbot.io/track?platform=twitter&v=0.8.2-rest&type=incoming&apiKey=<API_KEY>
`}</code></pre>
    <p>{`The data to POST should be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "text": "Blue Bird",
  "userId": "1234858592",
  "platformJson": {
    "direct_message_events": [
      {
        "type": "message_create",
        "id": "1234858589",
        "created_timestamp": "1392078023603",
        "message_create": {
          "target": {
            "recipient_id": "1234858592"
          },
          "sender_id": "3805104374",
          "source_app_id": "268278",
          "message_data": {
            "text": "Blue Bird",
            "entities": {
              "hashtags": [],
              "symbols": [],
              "urls": [],
              "user_mentions": []
            },
            "quick_reply_response": {
              "type": "options",
              "metadata": "external_id_2"
            },
            "attachment": {
              "type": "media",
              "media": {}
            }
          }
        }
      }
    ],
    "users": {
      "1234858592": {
        "id": "1234858592",
        "created_timestamp": "1415320482361",
        "name": "TwitterDev",
        "screen_name": "TwitterDev",
        "location": "Internet",
        "description": "Developer and Platform Relations @Twitter. We are developer advocates. We can't answer all your questions, but we listen to all of them!",
        "protected": false,
        "verified": true,
        "followers_count": 440643,
        "friends_count": 1534,
        "statuses_count": 2837,
        "profile_image_url": "http://pbs.twimg.com/profile_images/530814764687949824/npQQVkq8_normal.png",
        "profile_image_url_https": "https://pbs.twimg.com/profile_images/530814764687949824/npQQVkq8_normal.png"
      },
      "3805104374": {
        "id": "3805104374",
        "created_timestamp": "1449607341142",
        "name": "Furni",
        "screen_name": "furni",
        "location": "San Francisco, CA",
        "description": "Furni is Twitter's example company to showcase new developer features.",
        "protected": false,
        "verified": false,
        "followers_count": 297,
        "friends_count": 7,
        "statuses_count": 1,
        "profile_image_url": "http://pbs.twimg.com/profile_images/653712801219805185/S4LvoO9b_normal.png",
        "profile_image_url_https": "https://pbs.twimg.com/profile_images/653712801219805185/S4LvoO9b_normal.png"
      }
    },
    "apps": {
      "268278": {
        "id": "268278",
        "name": "Twitter Web Client",
        "url": "http://twitter.com"
      }
    }
  }
}
`}</code></pre>
    <h4>{`Sample cURL`}</h4>
    <pre><code parentName="pre" {...{}}>{`curl -X POST -H "Content-Type: application/json"
     -d '{"text":"Blue Bird","userId":"1234858592","platformJson":{"direct_message_events":[]}}'
     '<a href="https://tracker.dashbot.io/track?platform=twitter&v=0.8.2-rest&type=incoming&apiKey=API_KEY%27" rel="noopener" target="_blank">https://tracker.dashbot.io/track?platform=twitter&v=0.8.2-rest&type=incoming&apiKey=API_KEY'</a>
`}</code></pre>
    <h4>{`This is a sample incoming message.`}</h4>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/07/07120634/7613b-1iodnrspil_fjaki8f8m7dw.png)
    </figure>
### Useful resources at Twitter:
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dev.twitter.com/webhooks/receiving-events"
        }}>{`Twitter Receiving Events`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dev.twitter.com/rest/direct-messages"
        }}>{`Twitter Direct Message Beta Features`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      